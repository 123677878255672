/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Prompt_100Thin = require('./Prompt_100Thin.ttf');
export const Prompt_100Thin_Italic = require('./Prompt_100Thin_Italic.ttf');
export const Prompt_200ExtraLight = require('./Prompt_200ExtraLight.ttf');
export const Prompt_200ExtraLight_Italic = require('./Prompt_200ExtraLight_Italic.ttf');
export const Prompt_300Light = require('./Prompt_300Light.ttf');
export const Prompt_300Light_Italic = require('./Prompt_300Light_Italic.ttf');
export const Prompt_400Regular = require('./Prompt_400Regular.ttf');
export const Prompt_400Regular_Italic = require('./Prompt_400Regular_Italic.ttf');
export const Prompt_500Medium = require('./Prompt_500Medium.ttf');
export const Prompt_500Medium_Italic = require('./Prompt_500Medium_Italic.ttf');
export const Prompt_600SemiBold = require('./Prompt_600SemiBold.ttf');
export const Prompt_600SemiBold_Italic = require('./Prompt_600SemiBold_Italic.ttf');
export const Prompt_700Bold = require('./Prompt_700Bold.ttf');
export const Prompt_700Bold_Italic = require('./Prompt_700Bold_Italic.ttf');
export const Prompt_800ExtraBold = require('./Prompt_800ExtraBold.ttf');
export const Prompt_800ExtraBold_Italic = require('./Prompt_800ExtraBold_Italic.ttf');
export const Prompt_900Black = require('./Prompt_900Black.ttf');
export const Prompt_900Black_Italic = require('./Prompt_900Black_Italic.ttf');
